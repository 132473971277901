<div class="container expired-session-view">

  <div class="row pt-1 mt-3">
    <div class="logo mt-2 mb-2">
      <img src="/assets/img/white-ball-l-3x.png" srcset="/assets/img/white-ball-l-1x.png, /assets/img/white-ball-l-2x.png 2x, /assets/img/white-ball-l-3x.png 3x" alt="Logo" width="43" height="43"> S4N Soccer
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3 pt-3">
      <mat-card class="card-info p-2">
        <div class="text-center pt-3">
          <p>
            Como la empresa donde todo comenzó, hemos decidido llevar nuestra pasión por el fútbol entre amigos a
            un siguiente nivel. Nos emociona anunciarte que S4N Soccer ha evolucionado y ahora se presenta bajo un nuevo
            nombre y plataforma:
          </p>
          <img src="/assets/img/golito.png" width="200" alt="Golito"/>
          <p class="pt-3">
            <b>Golito</b> será es tu compañero perfecto para organizar partidos de fútbol con tus amigos de una manera
            más fácil y divertida. Hemos migrado para ofrecerte una interfaz más amigable, funcionalidades mejoradas y
            una experiencia de usuario que te hará disfrutar aún más de la previa.
          </p>
        </div>
        <div class="text-center">
          <a class="store-link me-3" href="https://play.google.com/store/apps/details?id=co.golito.app" title="Descargar para Android">
            <img src="/assets/img/play.png" width="150" alt="Google Play Store"/>
          </a>
          <a class="store-link" href="https://apps.apple.com/co/app/golito/id1" title="Descargar para iOS">
            <img src="/assets/img/store.svg" width="135" alt="App Store"/>
          </a>
        </div>
        <div class="text-center pt-3 mb-4">
          <a target="_blank" href="https://app.golito.co" mat-flat-button color="primary" [type]="'button'">IR A LA WEB</a>
        </div>
      </mat-card>
    </div>
  </div>

</div>

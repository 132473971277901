<div class="container expired-session-view">

  <div class="row pt-1 mt-3">
    <div class="logo mt-4 mb-4">
      <img src="/assets/img/white-ball-l-3x.png" srcset="/assets/img/white-ball-l-1x.png, /assets/img/white-ball-l-2x.png 2x, /assets/img/white-ball-l-3x.png 3x" alt="Logo" width="43" height="43"> S4N Soccer
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3 pt-4">
      <mat-card class="card-info p-2">
        <div class="text-center pt-3">
          <div>
            <h5 class="text-center mt-4">En mantenimiento</h5>
          </div>
          <p class="text-center mt-4 mb-3">
            A los mejores tambien nos toca. Estamos en mantenimiento pero pronto volveremos al aire.
          </p>
          <p class="text-center mt-4 mb-4">
            <mat-icon color="primary" [fontSet]="'material-icons-outlined'" class="icon-big">engineering</mat-icon>
          </p>
        </div>
      </mat-card>
    </div>
  </div>

</div>

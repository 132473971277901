import {Injectable} from '@angular/core';

import {FirebaseApp, initializeApp} from 'firebase/app';
import {fetchAndActivate, getRemoteConfig, getValue, RemoteConfig} from 'firebase/remote-config';
import {NgEventBus} from 'ng-event-bus';

import {env} from '../../../../environments/env';

import {RemoteConfigService} from './remote-config.service';
import {RemoteAppConfig} from '../../../models/remote-app-config';
import {AppLogger} from '../../../utils/app-logger';
import {BusEvents} from '../../../models/enums/bus-events';

@Injectable()
export class FirebaseService {

  constructor(private remoteConfigService: RemoteConfigService,
              private eventBus: NgEventBus) {
  }

  public start(): void {
    if (!env.app.activeFirebase) {
      return;
    }

    const app: FirebaseApp = initializeApp(env.firebase);

    this.fetchRemoteConfig(app).finally();
  }

  private async fetchRemoteConfig(app: FirebaseApp): Promise<void> {
    const remoteConfig: RemoteConfig = getRemoteConfig(app);
    remoteConfig.settings.minimumFetchIntervalMillis = 60000;

    await fetchAndActivate(remoteConfig);

    const value: string = getValue(remoteConfig, 'rc').asString();
    const config: RemoteAppConfig = {
      ...JSON.parse(value),
      loaded: true
    };

    this.remoteConfigService.setConfig(config);
    this.eventBus.cast<RemoteAppConfig>(BusEvents.APP_CONFIG, config);

    AppLogger.info('[FirebaseService] Remote config loaded!');
  }

}

import {NgModule} from '@angular/core';
import {NoPreloading, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    title: 'Iniciar sesión | S4N Soccer',
    loadChildren: () => import('./../login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'forgot',
    title: 'Recuperar clave | S4N Soccer',
    loadChildren: () => import('./../forgot/forgot.module').then(m => m.ForgotModule)
  },
  {
    path: 'register',
    title: 'Registro | S4N Soccer',
    loadChildren: () => import('./../register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'restore',
    title: 'Restaurar clave | S4N Soccer',
    loadChildren: () => import('./../restore/restore.module').then(m => m.RestoreModule)
  },
  {
    path: 'info',
    title: 'Información | S4N Soccer',
    loadChildren: () => import('./../help/help.module').then(m => m.HelpModule)
  },
  {
    path: 'events',
    title: 'Eventos | S4N Soccer',
    loadChildren: () => import('./../events/events.module').then(m => m.EventsModule)
  },
  {
    path: 'events/:id',
    title: 'Información del evento | S4N Soccer',
    loadChildren: () => import('./../event-detail/event-detail.module').then(m => m.EventDetailModule)
  },
  {
    path: 'announcements',
    title: 'Anuncios | S4N Soccer',
    loadChildren: () => import('./../announcements/announcements.module').then(m => m.AnnouncementsModule)
  },
  {
    path: 'announcements/new',
    title: 'Nuevo anuncio | S4N Soccer',
    loadChildren: () => import('./../announcement-new/announcement-new.module').then(m => m.AnnouncementNewModule)
  },
  {
    path: 'announcements/:id',
    title: 'Información del anuncio | S4N Soccer',
    loadChildren: () => import('./../announcement-detail/announcement-detail.module').then(m => m.AnnouncementDetailModule)
  },
  {
    path: 'management',
    title: 'Administración | S4N Soccer',
    loadChildren: () => import('./../management/management.module').then(m => m.ManagementModule)
  },
  {
    path: 'scheduling',
    title: 'Programación | S4N Soccer',
    loadChildren: () => import('./../scheduling/scheduling.module').then(m => m.SchedulingModule)
  },
  {
    path: 'scheduling/:id',
    title: 'Información del evento | S4N Soccer',
    loadChildren: () => import('./../scheduling-detail/scheduling-detail.module').then(m => m.SchedulingDetailModule)
  },
  {
    path: 'users',
    title: 'Usuarios | S4N Soccer',
    loadChildren: () => import('./../users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'profile',
    title: 'Mi Perfil | S4N Soccer',
    loadChildren: () => import('./../profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'profile/edit',
    title: 'Editar perfil | S4N Soccer',
    loadChildren: () => import('./../profile-edit/profile-edit.module').then(m => m.ProfileEditModule)
  },
  {
    path: 'profile/edit/image',
    title: 'Editar imagen de perfil | S4N Soccer',
    loadChildren: () => import('./../profile-image-edit/profile-image-edit.module').then(m => m.ProfileImageEditModule)
  },
  {
    path: 'profile/edit/password',
    title: 'Editar clave | S4N Soccer',
    loadChildren: () => import('./../profile-password-edit/profile-password-edit.module').then(m => m.ProfilePasswordEditModule)
  },
  {
    path: 'help',
    title: 'Ayuda | S4N Soccer',
    loadChildren: () => import('./../help/help.module').then(m => m.HelpModule)
  },
  {
    path: 'faqs',
    title: 'FAQs | S4N Soccer',
    loadChildren: () => import('./../faqs/faqs.module').then(m => m.FaqsModule)
  },
  {
    path: 'privacy-policy',
    title: 'Política de Privacidad | S4N Soccer',
    loadChildren: () => import('./../privacy/privacy.module').then(m => m.PrivacyModule)
  },
  {
    path: 'terms-and-conditions',
    title: 'Términos & Condiciones | S4N Soccer',
    loadChildren: () => import('./../terms/terms.module').then(m => m.TermsModule)
  },
  // {
  //   path: 'scheduling/new',
  //   title: 'Nuevo evento | S4N Soccer',
  //   loadChildren: () => import('./modules/scheduling-new/scheduling-new.module').then(m => m.SchedulingNewModule)
  // },
  // {
  //   path: 'scheduling/:id',
  //   title: 'Información del evento programado | S4N Soccer',
  //   loadChildren: () => import('./modules/scheduling-detail/scheduling-detail.module').then(m => m.SchedulingDetailModule)
  // },
  // {
  //   path: 'scheduling/:id/new',
  //   title: 'Programar nuevo evento | S4N Soccer',
  //   loadChildren: () => import('./modules/scheduling-new/scheduling-new.module').then(m => m.SchedulingNewModule)
  // },
  // {
  //   path: 'scheduling/:id/edit',
  //   title: 'Editar evento | S4N Soccer',
  //   loadChildren: () => import('./modules/scheduling-edit/scheduling-edit.module').then(m => m.SchedulingEditModule)
  // },
  // {
  //   path: 'users/:id',
  //   title: 'Información del usuario | S4N Soccer',
  //   loadChildren: () => import('./modules/user-detail/user-detail.module').then(m => m.UserDetailModule)
  // },
  // {
  //   path: 'stats',
  //   title: 'Estadísticas | S4N Soccer',
  //   loadChildren: () => import('./modules/stats/stats.module').then(m => m.StatsModule)
  // },
  // {
  //   path: 'notifications',
  //   title: 'Notificaciones | S4N Soccer',
  //   loadChildren: () => import('./modules/notifications/notifications.module').then(m => m.NotificationsModule)
  // },
  // {
  //   path: 'notifications/new',
  //   title: 'Enviar nueva notificación | S4N Soccer',
  //   loadChildren: () => import('./modules/notification-new/notification-new.module').then(m => m.NotificationNewModule)
  // },
  // {
  //   path: 'devices',
  //   title: 'Dispositivos | S4N Soccer',
  //   loadChildren: () => import('./modules/devices/devices.module').then(m => m.DevicesModule)
  // },
  // {
  //   path: 'history',
  //   title: 'Historial | S4N Soccer',
  //   loadChildren: () => import('./modules/history/history.module').then(m => m.HistoryModule)
  // },
  // {
  //   path: 'history/:id',
  //   title: 'Información del evento pasado | S4N Soccer',
  //   loadChildren: () => import('./modules/history-detail/history-detail.module').then(m => m.HistoryDetailModule)
  // },
  {
    path: '404',
    title: 'Página no encontrada | S4N Soccer',
    loadChildren: () => import('./../not-found/not-found.module').then(m => m.NotFoundModule)
  },
  {
    path: '**',
    title: 'Página no encontrada | S4N Soccer',
    loadChildren: () => import('./../not-found/not-found.module').then(m => m.NotFoundModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        enableTracing: false,
        useHash: false,
        preloadingStrategy: NoPreloading,
        onSameUrlNavigation: 'ignore',
        scrollPositionRestoration: 'enabled'
      }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}

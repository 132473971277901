import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';

import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, finalize, last, switchMap, take} from 'rxjs/operators';
import {NgEventBus} from 'ng-event-bus';

import {AuthService} from './auth.service';
import {StoreService} from './store.service';
import {RefreshDTO} from '../../../models/dto/refresh-dto';
import {AuthDTO} from '../../../models/dto/auth-dto';
import {AuthLevel} from '../../../models/enums/auth-level';
import {BusEvents} from '../../../models/enums/bus-events';
import {ApiResponse} from '../../../models/api-response';
import {Auth} from '../../../models/auth';
import {ApiUtils} from '../../../utils/api-utils';
import {AppLogger} from '../../../utils/app-logger';

@Injectable()
export class AuthInterceptorService {

  private isRefreshingToken = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private router: Router,
              private eventBus: NgEventBus,
              private authService: AuthService,
              private storeService: StoreService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request = this.generateRequest(req);

    return next.handle(request).pipe(
      last(),
      catchError((error: any) => {

        // Validar que sea un error de autenticación (401)
        if (error.status !== 401) {
          AppLogger.warn('[AuthInterceptor] Error in API response');
          return throwError(() => error);
        }

        // Validar si el código de error corresponde al token de acceso caducado
        try {
          const code: number = error.error.error.code * 1;

          switch (code) {
            case 11:
              AppLogger.warn('[AuthInterceptor] Refresh token not found');
              this.eventBus.cast(BusEvents.APP_SESSION_EXPIRED);
              return throwError(() => error);
            case 12:
              AppLogger.warn('[AuthInterceptor] Refresh token expired');
              this.eventBus.cast(BusEvents.APP_SESSION_EXPIRED);
              return throwError(() => error);
            case 13:
              AppLogger.warn('[AuthInterceptor] Access token not found');
              this.eventBus.cast(BusEvents.APP_SESSION_EXPIRED);
              return throwError(() => error);
            case 18:
              AppLogger.warn('[AuthInterceptor] Access token expired, trying to refresh...');
              break;
            default:
              AppLogger.warn('[AuthInterceptor] Error different from session expiration');
              this.eventBus.cast(BusEvents.APP_SESSION_EXPIRED);
              return throwError(() => error);
          }
        } catch (e) {
          AppLogger.warn('[AuthInterceptor] Attempt to read error code was not successful', e);
          return throwError(() => error);
        }

        return this.refreshAccessToken(request, next);
      })
    );
  }

  private generateRequest(req: HttpRequest<any>): HttpRequest<any> {
    const url = `${req.url}`;
    const method = `${req.method}`;

    // Validar el tipo de seguridad que debe tener el endpoint
    const secureType: AuthLevel = req.context.get(ApiUtils.AUTH_LEVEL_CTX);

    // valida tipo de token a asociar
    if (secureType === AuthLevel.USER) {
      const token: string | undefined = this.storeService.getAuth()?.at;

      if (token) {
        return req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
      } else {
        AppLogger.warn('[AuthInterceptor] Access token not found');
      }
    }

    AppLogger.info(`[AuthInterceptor] ${method}: ${url}`);
    return req;
  }

  private refreshAccessToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isRefreshingToken) {
      AppLogger.info('[AuthInterceptor] Refreshing token in progress...');

      return this.refreshTokenSubject
        .pipe(
          filter(result => result !== null),
          take(1),
          switchMap(() => next.handle(this.generateRequest(request)).pipe(last()))
        );
    }

    this.isRefreshingToken = true;
    this.refreshTokenSubject.next(null);

    const oldAuth: Auth | undefined = this.storeService.getAuth();
    let refreshDTO: RefreshDTO = {
      userId: '',
      accessToken: '',
      refreshToken: ''
    };

    if (oldAuth) {
      refreshDTO = {
        userId: oldAuth.id,
        accessToken: oldAuth.at,
        refreshToken: oldAuth.rt
      };
    }

    return this.authService.refreshToken(refreshDTO)
      .pipe(
        switchMap((r: ApiResponse<AuthDTO>) => {
          if (!r.success) {
            return throwError(() => r.error.api);
          }

          const newAuth: Auth = {
            id: r.data.id,
            at: r.data.accessToken,
            rt: r.data.refreshToken,
            sc: r.data.scope,
            ea: r.data.expiresAt
          };

          this.storeService.setAuth(newAuth);
          AppLogger.info('[AuthInterceptor] Access token refreshed correctly');

          this.refreshTokenSubject.next(r);
          return next.handle(this.generateRequest(request)).pipe(last());
        }),
        catchError((e) => {
          AppLogger.error('[AuthInterceptor] Error refreshing access token', e);
          this.eventBus.cast(BusEvents.APP_SESSION_EXPIRED);
          return throwError(() => e);
        }),
        finalize(() => this.isRefreshingToken = false)
      );
  }

}

import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {NgOptimizedImage} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {
  LuxonDateAdapter,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MAT_LUXON_DATE_FORMATS
} from '@angular/material-luxon-adapter';

import {NgEventBus} from 'ng-event-bus';
import {provideHotToastConfig} from '@ngneat/hot-toast';

import {env} from '../../../environments/env';

import {AppRoutingModule} from './app-routing.module';

import {AuthInterceptorService} from './services/auth-interceptor.service';
import {ErrorHandlerService} from './services/error-handler.service';
import {RollbarService} from './services/rollbar.service';
import {CheckForUpdateService} from './services/check-for-update.service';
import {LogUpdateService} from './services/log-update.service';
import {LoaderService} from './services/loader.service';
import {WebPushNotificationService} from './services/web-push-notification.service';
import {AuthService} from './services/auth.service';
import {ApiService} from './services/api.service';
import {SyncService} from './services/sync.service';
import {FacebookService} from './services/facebook.service';
import {GoogleService} from './services/google.service';
import {ToastService} from './services/toast.service';
import {AlertService} from './services/alert.service';
import {ModalService} from './services/modal.service';
import {WebPushService} from './services/web-push.service';
import {StoreService} from './services/store.service';
import {StartupService} from './services/startup.service';
import {SessionService} from './services/session.service';
import {FirebaseService} from './services/firebase.service';
import {RemoteConfigService} from './services/remote-config.service';

import {AppComponent} from './app.component';
import {AlertComponent} from './components/alert/alert.component';
import {ExpiredSessionComponent} from './components/expired-session/expired-session.component';
import {MaintenanceMessageComponent} from './components/maintenance-message/maintenance-message.component';
import {MigrationMessageComponent} from './components/migration-message/migration-message.component';
import {ToastComponent} from './components/toast/toast.component';

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    ExpiredSessionComponent,
    MaintenanceMessageComponent,
    MigrationMessageComponent,
    ToastComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    NgOptimizedImage,
    ServiceWorkerModule.register('sw.js', {
      enabled: env.app.activeSW,
      scope: './',
      registrationStrategy: 'registerImmediately'
    })
  ],
  providers: [
    AlertService,
    AuthService,
    ApiService,
    FacebookService,
    FirebaseService,
    GoogleService,
    LoaderService,
    LogUpdateService,
    CheckForUpdateService,
    ModalService,
    NgEventBus,
    RemoteConfigService,
    SessionService,
    StartupService,
    StoreService,
    SyncService,
    ToastService,
    WebPushNotificationService,
    WebPushService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    {
      provide: RollbarService,
      useFactory: ErrorHandlerService.rollbarFactory
    },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: {
        useUtc: false
      }
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'es'
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_LUXON_DATE_FORMATS
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always'
      }
    },
    provideHotToastConfig({
      position: 'bottom-right',
      theme: 'snackbar'
    })
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}

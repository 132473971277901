import {Injectable} from '@angular/core';

import {NgEventBus} from 'ng-event-bus';

import {env} from '../../../../environments/env';

import {CodeClientConfigDTO} from '../../../models/dto/code-client-config-dto';
import {CodeResponseDTO} from '../../../models/dto/code-response-dto';
import {CodeResponseErrorDTO} from '../../../models/dto/code-response-error-dto';
import {BusEvents} from '../../../models/enums/bus-events';
import {AppLogger} from '../../../utils/app-logger';

declare const window: any;

@Injectable()
export class GoogleService {

  private static readonly MAX_ERROR_COUNTER: number = 60;
  private errorCounter: number;
  private loaded: boolean;

  constructor(private eventBus: NgEventBus) {
    this.errorCounter = 0;
    this.loaded = false;
  }

  public start(): void {
    if (this.loaded) {
      return;
    }

    if (this.errorCounter === GoogleService.MAX_ERROR_COUNTER) {
      AppLogger.warn('[Google] window.google not defined, reached max attempts');
      return;
    }

    this.errorCounter++;

    if (window.google) {
      this.initGoogleSigIn();
    } else {
      AppLogger.warn('[Google] window.google not defined, trying...');
      setTimeout(() => this.start(), 1000);
    }

  }

  private initGoogleSigIn(): void {
    if (this.loaded) {
      return;
    }

    try {
      const config: CodeClientConfigDTO = {
        client_id: env.google.clientId,
        scope: 'openid profile email',
        ux_mode: 'popup',
        select_account: false,
        enable_serial_consent: true,
        callback: (response: CodeResponseDTO) => this.eventBus.cast(BusEvents.APP_GOOGLE, response),
        error_callback: (error: CodeResponseErrorDTO) => this.eventBus.cast(BusEvents.APP_GOOGLE, error)
      };

      window.GoogleClient = window.google.accounts.oauth2.initCodeClient(config);
      this.loaded = true;
      this.onInit();
    } catch (e) {
      this.loaded = false;
      this.onError(e);
    }
  }

  private onInit(): void {
    AppLogger.info('[Google] SDK loaded');
  }

  private onError(err: any): void {
    AppLogger.error('[Google] SDK error', err);
  }

}

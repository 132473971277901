import {Component} from '@angular/core';

@Component({
  selector: 'app-migration-message',
  templateUrl: './migration-message.component.html',
  styleUrls: ['./migration-message.component.scss']
})
export class MigrationMessageComponent {

}

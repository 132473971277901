import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

import {Subscription} from 'rxjs';
import {MetaData, NgEventBus} from 'ng-event-bus';

import {env} from '../../../environments/env';

import {LoaderService} from './services/loader.service';
import {FacebookService} from './services/facebook.service';
import {FirebaseService} from './services/firebase.service';
import {GoogleService} from './services/google.service';
import {StartupService} from './services/startup.service';
import {StoreService} from './services/store.service';
import {BusEvents} from '../../models/enums/bus-events';
import {AppLogger} from '../../utils/app-logger';
import {AppUtils} from '../../utils/app-utils';

const CARD_HAND_ICON: string = `<svg xmlns="http://www.w3.org/2000/svg" width="512pt" height="512pt" viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet"><g transform="translate(0,512) scale(0.1,-0.1)" fill="currentColor" stroke="none"><path d="M1202 5104 c-152 -40 -269 -165 -307 -326 -22 -95 -22 -3045 0 -3131 41 -159 150 -278 295 -323 35 -11 134 -14 461 -14 l417 0 5 -268 c4 -223 8 -281 25 -352 47 -194 97 -305 226 -500 46 -69 92 -140 102 -157 l18 -33 93 0 94 0 -17 33 c-24 46 -99 167 -172 276 -114 170 -171 309 -197 481 -21 137 -22 1350 -1 1427 18 69 46 112 108 168 150 135 386 79 476 -113 l27 -57 5 -570 c3 -314 9 -578 14 -588 19 -40 79 -49 117 -18 22 18 24 28 29 138 l5 118 75 6 c178 14 304 92 384 239 44 80 56 162 56 383 0 108 3 197 6 197 3 0 46 -24 97 -53 51 -30 162 -91 247 -137 85 -45 178 -98 207 -117 92 -61 151 -150 179 -270 20 -84 18 -101 -45 -524 -27 -184 -55 -383 -61 -444 -7 -60 -17 -119 -22 -130 -5 -12 -24 -116 -42 -233 l-32 -212 81 0 82 0 16 92 c9 51 30 194 47 317 16 123 32 231 34 240 3 9 29 167 58 351 64 407 68 518 26 640 -15 41 -38 95 -51 119 -35 61 -119 155 -172 191 -51 35 -284 163 -502 275 l-153 78 -2 781 c-1 430 -2 984 -2 1231 -1 402 -3 455 -19 499 -52 147 -165 255 -303 291 -85 22 -1930 21 -2012 -1z"/></g></svg>`;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  private sessionSub!: Subscription;
  private expiredSessionSub!: Subscription;

  public render: boolean = false;
  public expiredSession: boolean = false;
  public showMigrationMessage: boolean = false;
  public showMaintenanceMessage: boolean = false;

  constructor(private startupService: StartupService,
              private facebookService: FacebookService,
              private googleService: GoogleService,
              private firebaseService: FirebaseService,
              private eventBus: NgEventBus,
              private loaderService: LoaderService,
              private iconRegistry: MatIconRegistry,
              private storeService: StoreService,
              private sanitizer: DomSanitizer) {
    this.iconRegistry.addSvgIconLiteral('card-hand', this.sanitizer.bypassSecurityTrustHtml(CARD_HAND_ICON));
    this.storeService.setGeneral({search: location.search, readAnnouncement: false, openAnnouncement: false});
  }

  ngOnInit(): void {
    if (env.app.activeEventBusLogger) {
      this.eventBus.on(BusEvents.APP_ALL).subscribe((value: MetaData) => AppLogger.info('[App] Event bus:', value.key));
    }

    this.eventBus.on(BusEvents.APP_CONFIG).subscribe((value: MetaData): void => {
      if (value.data.migration) {
        AppLogger.info('[App] Showing migration message');
        this.showMigrationMessage = true;
        return;
      }

      if (value.data.maintenance) {
        AppLogger.info('[App] Showing maintenance maintenance');
        this.showMaintenanceMessage = true;
      }
    });

    this.sessionSub = this.eventBus.on<{ name: string, email: string }>(BusEvents.APP_SESSION)
      .subscribe((m: MetaData<{ name: string, email: string }>): void => {
        this.activateSessionListener();
        this.sessionSub?.unsubscribe();
      })

    this.startupService.checkSession(this);
    this.startExternalOperations();
  }

  private startExternalOperations(): void {
    this.googleService.start();
    this.facebookService.start();
    this.facebookService.start();
    this.firebaseService.start();
    this.loaderService.load();
  }

  private activateSessionListener(): void {
    this.expiredSessionSub = this.eventBus.on(BusEvents.APP_SESSION_EXPIRED)
      .subscribe((): void => {
        this.renderSession();
        this.expiredSessionSub?.unsubscribe();
      });
  }

  private renderSession(): void {
    AppLogger.info('[App] Rendering session error!');
    this.render = true;
    this.expiredSession = true;
    AppUtils.deleteLoader();
  }

}

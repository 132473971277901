import {Injectable} from '@angular/core';

import {env} from '../../../../environments/env';

import {AppLogger} from '../../../utils/app-logger';

declare const window: any;

@Injectable()
export class FacebookService {

  private static readonly MAX_ERROR_COUNTER: number = 60;

  private errorCounter: number;

  constructor() {
    this.errorCounter = 0;

    window.soccer = window.soccer || {};
    window.soccer.facebook = false;
    window.soccer.facebookLoading = false;
    window.soccer.facebookUserSession = false;
  }

  public start(): void {
    if (window.soccer.facebookLoading) {
      return;
    }

    if (this.errorCounter === FacebookService.MAX_ERROR_COUNTER) {
      AppLogger.warn('[Facebook] window.FB not defined, reached max attempts');
      return;
    }

    this.errorCounter++;

    if (window.FB) {
      this.initFacebookSigIn();
    } else {
      AppLogger.warn('[Facebook] window.FB not defined, trying again...');
      setTimeout(() => this.start(), 1000);
    }

  }

  private initFacebookSigIn(): void {
    if (window.soccer.facebookLoading) {
      return;
    }

    window.FB.init({
      appId: env.facebook.appId,
      version: env.facebook.apiVersion,
      cookie: true,
      status: true,
      xfbml: false
    });

    window.FB.getLoginStatus(() => {
      window.soccer.facebook = true;
      AppLogger.info('[Facebook] SDK loaded');
    });

    window.soccer.facebookLoading = true;
  }

}

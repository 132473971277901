import {Injectable} from '@angular/core';

import {RemoteAppConfig} from '../../../models/remote-app-config';

@Injectable()
export class RemoteConfigService {

  private config: RemoteAppConfig;

  constructor() {
    this.config = {
      loaded: false,
      test: true,
      maintenance: false,
      migration: false
    };
  }

  public getProperty<T>(property: string): T | undefined {
    if (this.config[property]) {
      return this.config[property] as T;
    }

    return undefined;
  }

  public setConfig(config: RemoteAppConfig): void {
    this.config = config;
  }
}

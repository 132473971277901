import {ErrorHandler, Inject, Injectable} from '@angular/core';

import Rollbar, {Configuration, Dictionary} from 'rollbar';

import {env} from '../../../../environments/env';

import {RollbarService} from './rollbar.service';
import {AppLogger} from '../../../utils/app-logger';

declare const window: any;

@Injectable()
export class ErrorHandlerService implements ErrorHandler {

  public static readonly ROLLBAR_CONFIG: Configuration = {
    accessToken: env.rollbar.accessToken,
    addErrorContext: true,
    autoInstrument: true,
    captureEmail: true,
    captureIp: true,
    captureLambdaTimeouts: true,
    captureUncaught: true,
    captureUnhandledRejections: true,
    captureUsername: true,
    enabled: env.app.activeRollbar,
    environment: 'production',
    payload: {
      context: '',
      environment: 'production',
      server: {
        branch: 'main',
        host: window.location.host
      }
    },
    transform: (data: Dictionary) => {
      data['framework'] = 'angular';
      data['custom'] = {
        version: env.version
      };
      data['context'] = window.location.pathname;
      data['code_version'] = env.rollbar.codeVersion;
      data['timestamp'] = Math.floor(new Date().getTime() / 1000);
    },
    sendConfig: false
  };

  constructor(@Inject(RollbarService) private rollbar: Rollbar) {
    window.Rollbar = this.rollbar;
  }

  handleError(error: any): void {
    const e = error.originalError || error;

    if (String(error).indexOf('Error: API key required') > 0) {
      return;
    }

    if (e !== undefined && e !== null) {
      AppLogger.errorFromHandler(e);
      this.rollbar.error('[ErrorHandlerService]', e);
    }

  }

  public static rollbarFactory(): Rollbar {
    return new Rollbar(ErrorHandlerService.ROLLBAR_CONFIG);
  }

}

<div class="container expired-session-view">

  <div class="row pt-1 mt-3">
    <div class="logo mt-3 mb-4">
      <img src="/assets/img/white-ball-l-3x.png" srcset="/assets/img/white-ball-l-1x.png, /assets/img/white-ball-l-2x.png 2x, /assets/img/white-ball-l-3x.png 3x" alt="Logo" width="43" height="43"> S4N Soccer
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-4 offset-md-3 offset-lg-4 pt-3">
      <mat-card class="card-info p-4">
        <div>
          <h5 class="text-center mt-4">Sesión finalizada</h5>
        </div>
        <div>
          <p class="text-center pt-3">
            Tu sesión actual ha sido finalizada porque expiró o porque ocurrió otro error no específico. Inicia sesión
            nuevamente dando clic al botón en la parte inferior.
          </p>
        </div>
        <div class="text-center pt-3 mb-4">
          <button mat-flat-button color="primary" [type]="'button'" (click)="logout()">INICIAR SESIÓN</button>
        </div>
      </mat-card>
    </div>
  </div>

</div>

import {Component} from '@angular/core';

import {SessionService} from '../../services/session.service';

@Component({
  selector: 'app-expired-session',
  templateUrl: './expired-session.component.html',
  styleUrls: ['./expired-session.component.scss']
})
export class ExpiredSessionComponent {

  constructor(private sessionService: SessionService) {
  }

  public logout(): void {
    this.sessionService.logout();
  }

}

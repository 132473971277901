import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {enableProdMode, VERSION} from '@angular/core';

import {env} from './environments/env';
import {AppLogger} from './app/utils/app-logger';

import {AppModule} from './app/modules/app/app.module';

AppLogger.info(`[Main] == ${env.name} ==`);
AppLogger.info(`[Main] Version: ${env.version}, Environment: ${env.environment}, Angular: ${VERSION.full}`);

if (env.app.activeProdMode) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

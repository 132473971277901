export class ApiRealtimeEvents {

  public static readonly STARTED_API: string = 'started-api';

  public static readonly ANNOUNCEMENTS: string = 'update-announcements';
  public static readonly ANNOUNCEMENTS_READERS: string = 'update-announcements-readers';
  public static readonly EMAILS: string = 'update-emails';
  public static readonly EVENTS: string = 'update-events';
  public static readonly HISTORY: string = 'update-history';
  public static readonly PEOPLE: string = 'update-people';
  public static readonly PUSH_DEVICES: string = 'update-pushdevices';
  public static readonly PUSH_MESSAGES: string = 'update-pushmessages';
  public static readonly USERS: string = 'update-users';
  public static readonly WEB: string = 'update-web';

}

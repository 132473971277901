import {Component} from '@angular/core';

@Component({
  selector: 'app-maintenance-message',
  templateUrl: './maintenance-message.component.html',
  styleUrls: ['./maintenance-message.component.scss']
})
export class MaintenanceMessageComponent {

}
